import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ModalPlay = () => {
  const [showModal, setShowModal] = useState(true);
  const { t } = useTranslation();
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div className={`modal fade modal-standard ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" id="modalplay" aria-modal="true" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ background: "rgba(40, 113, 251, 0.1)" }}>
            <img src="/image/logo2.png" width="147" height="34" alt="Logo" />
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p className="mb-0 login-msg">{t('58')}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>{t('59')}</button>
            <NavLink to="/home" className="btn btn-primary-login">{t('54')}</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPlay;
